/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'templates/Layout';
import FactBoxes from 'modules/FactBoxes';
import Text from 'modules/Text';
import TextAndImage from 'modules/TextAndImage';
import TextAndVideo from 'modules/TextAndVideo';
import NewsletterSlim from 'modules/NewsletterSlim';
import RelatedRibbon from 'modules/RelatedRibbon';
import Timeline from 'modules/Timeline';
import StickyCTA from 'modules/StickyCTA';
import EcospheresNav from 'components/EcospheresNav';
import EcosphereCompanies from 'modules/EcosphereCompanies';
import EcosphereCluster from 'modules/EcosphereCluster';
import HeroWithIllustration from 'modules/HeroWithIllustration';

export const ecosystemPageQuery = graphql`
  query {
    allDatoCmsDanishStartupsInNumbersPage(sort: { fields: position }) {
      edges {
        node {
          slug
          title
        }
      }
    }
    datoCmsDanishStartupsInNumbersIndex {
      seo {
        title
        description
        image {
          url
        }
      }
      blocks {
        ... on DatoCmsHeroWithIllustration {
          model {
            apiKey
          }
          heading
          bodyTextNode {
            childMarkdownRemark {
              html
            }
          }
          callToActions {
            label
            href
          }
          illustration {
            ecoid
          }
        }
        ... on DatoCmsTextImage {
          model {
            apiKey
          }
          heading
          bodyTextNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(placeholder: BLURRED, width: 640)
          }
          reverseOrder
        }
        ... on DatoCmsTextVideo {
          model {
            apiKey
          }
          heading
          bodyTextNode {
            childMarkdownRemark {
              html
            }
          }
          videoEmbedUrl
          reverseOrder
        }
        ... on DatoCmsTextStartupBubble {
          model {
            apiKey
          }
          heading
          bodyText
        }
        ... on DatoCmsFactBox {
          model {
            apiKey
          }
          heading
          facts {
            heading
            bodyText
          }
          bigFont15Degrees
        }
        ... on DatoCmsNewsletterSlim {
          model {
            apiKey
          }
          mailchimpSignUpFrom
          callToActionLabel
          heading
        }
        ... on DatoCmsInPartnershipWith {
          model {
            apiKey
          }
          heading
        }
        ... on DatoCmsEcosphereClustersList {
          model {
            apiKey
          }
          heading
          ecospheres {
            ecoid
            clusterWebsite
            clusterLogo {
              width
              height
              blurhash
              url
              gatsbyImageData(width: 400)
              alt
              title
            }
            clusterWhiteLogo {
              width
              height
              blurhash
              url
              gatsbyImageData(width: 400)
              alt
              title
            }
            icon {
              url
            }
          }
        }
        ... on DatoCmsStickyCallToAction {
          model {
            apiKey
          }
          text
          callToAction {
            label
            href
          }
          alternativeCallToActionLabel
        }
      }
    }
  }
`;

function EcosystemPage({ data }) {
  const { datoCmsDanishStartupsInNumbersIndex, allDatoCmsDanishStartupsInNumbersPage } = data;
  const pages = allDatoCmsDanishStartupsInNumbersPage.edges.map((eco) => eco.node);

  const components = datoCmsDanishStartupsInNumbersIndex?.blocks.map((block, i) => {
    if (block?.model) {
      const { apiKey } = block.model;
      const key = apiKey + i;

      switch (apiKey) {
        case 'hero_with_illustration':
          return <HeroWithIllustration {...block} key={key} />;
        case 'text_video':
          return <TextAndVideo {...block} key={key} isHero={i === 0} />;
        case 'sticky_call_to_action':
          return <StickyCTA {...block} key={key} />;
        case 'fact_box':
          return <FactBoxes {...block} key={key} />;
        case 'text_image':
          return <TextAndImage {...block} key={key} />;
        case 'timeline':
          return <Timeline {...block} key={key} />;
        case 'related_ribbon':
          return <RelatedRibbon {...block} key={key} />;
        case 'text':
          return <Text {...block} key={key} />;
        case 'newsletter_slim':
          return <NewsletterSlim {...block} key={key} />;
        case 'ecosphere_companies_database':
          return <EcosphereCompanies {...block} key={key} />;
        case 'ecosphere_cluster':
          return <EcosphereCluster {...block} key={key} />;
        default:
          break;
      }
    }
    return null;
  });

  return (
    <Layout seo={datoCmsDanishStartupsInNumbersIndex?.seo}>
      <EcospheresNav
        pages={[
          {
            slug: 'all-startups',
            title: 'All',
          },
          ...pages,
          {
            slug: 'about',
            title: 'About',
          },
        ]}
        breadcrumbs={[
          {
            href: '/',
            title: 'Ecosystems',
          },
          {
            href: '/all-startups',
            title: 'All',
          },
        ]}
      />

      {components}

      <EcosphereCompanies />
    </Layout>
  );
}

export default EcosystemPage;
